$white: #ffffff;
$blue: #0072FF;
$black: #080D15;

$transitionTime: 0.3s;

// Icons
$icon: (
  "width": 105px,
  "height": 105px 
);