@import './variables';
@import './_normalize.scss';

/* Mixins
   ========================================================================== */

@mixin xs {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}

/* Global
   ========================================================================== */

section.container,
footer.container {
  padding: 6% 9%;
  overflow: hidden;
}

* {
  color: $white;
}

p {
  line-height: 1.5em;
  font-size: 16px;
}

button {
  font-size: 14px;
  color: $white;
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  background: transparent;
  border: 1px solid #fafafa;
  border-radius: 21px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.4s ease-in-out;

  & + button {
    margin-top: 20px;
  }

  &:hover,
  &.primary {
    background-color: $blue;
    border-color: $blue;
  }

  &:focus {
    outline: none;
  }
}

h2.title {
  font-family: 'Exo 2';
  font-weight: 600;
  font-size: 41px;
  margin-top: 0;
  margin-bottom: 25px;
}

.badge {
  background-color: #0072ff;
  font-family: 'Open Sans';
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 8px;

  &:hover {
    // box-shadow: inset 1px 1px 1px #242020;
    cursor: default;
    box-shadow: 2px 2px 1px #cccccc;
  }
}

/* Landing page
   ========================================================================== */

section.landing-page {
  background-color: #08091c;
  padding-top: 1.5%;

  nav.main-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .brand > .site-logo {
      width: 133px;
    }

    .nav-list {
      visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      background: #08091c;
      margin: 0;
      padding: 0;
      padding-top: 50px;
      text-align: center;
      z-index: 1000;
      list-style-type: none;
      width: 100vw;
      height: 100vh;

      li {
        margin-bottom: 20px;

        a {
          text-decoration: none;
          font-family: Roboto;
        }

        @include lg {
          display: inline;
          margin-right: 45px;
        }
      }

      .close-btn {
        position: fixed;
        right: 30px;
        top: 30px;

        img {
          width: 30px;
        }
      }

      @include lg {
        display: inherit;
        position: inherit;
        width: unset;
        height: unset;
        padding: unset;

        .close-btn {
          display: none;
        }
      }
    }

    .hamburger {
      $max: 30px;
      background: url(../img/hamburger.svg) no-repeat;
      width: $max;
      height: $max - 10;

      @include lg {
        display: none;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include md {
      flex-direction: row;
    }

    article,
    aside {
      flex: 1;
    }

    article {
      margin-bottom: 30px;

      p {
        font-family: 'Open Sans';

        a {
          color: $blue;
          text-decoration: none;
          font-size: small;
        }
      }

      footer {
        margin-top: 35px;
      }
    }

    aside {
      text-align: center;
      img.intro-video {
        width: 300px;

        @include xs {
          max-width: 100%;
        }

        @include lg {
          width: 380px;
        }
      }
    }
  }
}

/* About page
   ========================================================================== */

section.about-page {
  background: #080d15 url('../img/Group\ 17.svg') no-repeat right top;
  background-size: 93%;

  article {
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans';
    font-weight: normal;

    @include md {
      flex-direction: row;
    }

    .content,
    aside {
      flex: 1;
    }

    .content {
      padding-right: 15px;
      margin-bottom: 20px;

      p:first-child {
        margin-top: 0;
      }

      p > a {
        text-decoration: none;
        color: $blue;
      }
    }

    aside {
      .checklist-actions {
        text-align: left;

        .checklist {
          .checklist-item {
            display: table;
            background: #252739;
            border-radius: 17.5px;
            padding: 8px 20px;
            margin-bottom: 20px;
            font-size: 17px;

            &::before {
              content: url(../img/tick.svg);
              // background-size: 18px 14px;
              display: inline-block;
              margin-right: 10px;
              width: 14px;
              height: 10px;
            }

            &:last-of-type {
              margin-bottom: 30px;
            }
          }
        }

        @include lg {
          margin-left: 20%;
        }
      }
    }
  }
}

/* Services page
   ========================================================================== */

section.services-page {
  background: $white url('../img/Group\ 20.svg') no-repeat top left;
  background-size: 70%;
  color: $black;

  p {
    color: $black;
    font-family: 'Open Sans';
  }

  article.services-info {
    margin-bottom: 40px;

    header > .title {
      color: $black;
    }

    @include lg {
      padding-right: 55%;
    }
  }

  .cards {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: column;

    .card {
      padding: 10px 25px;
      cursor: pointer;
      border-bottom: 19px solid transparent;
      border-radius: 9px;
      transition-property: background-color, box-shadow, border;
      transition-duration: $transitionTime;
      transition-timing-function: ease-in-out;

      &:first-child {
        margin-bottom: 30px;
      }

      header {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-bottom: 30px;

        .icon-wrapper {
          display: flex;
          justify-content: center;
          min-width: map-get($icon, width);
          min-height: map-get($icon, height);
          background-color: $blue;
          border-radius: 100%;

          @include md {
            margin-right: 45px;
          }
        }

        img.icon {
          width: 46px;
        }

        h3 {
          font-family: 'Exo 2';
          font-weight: 600;
          font-size: 36px;
          margin: 0;
          color: $black;
          margin-top: 10px;
        }

        @include sm {
          flex-direction: row;
          text-align: left;
        }
      }

      footer.on-hover {
        padding: 15px 0;
        visibility: hidden;
        // display: none;
        transition: visibility $transitionTime/3;

        strong {
          color: $blue;
          font-family: 'Open Sans';
          font-weight: bold;
          margin-right: 15px;
        }

        img {
          width: 46px;
          vertical-align: text-top;
        }
      }

      * {
        pointer-events: none;
      }

      &:hover,
      &.active {
        background-color: #fcfcff;
        box-shadow: 0 8px 15px lighten(#080d15, 80%);
        border-color: $blue;

        .on-hover {
          visibility: visible;
        }
      }

      @include md {
        padding: 30px 45px;
        margin-bottom: 0px !important;
      }

      @include lg {
        width: 38%;
      }
    }

    @include lg {
      flex-direction: row;
    }
  }
}

/* Teams (Squad) page
   ========================================================================== */

section.teams-page {
  background: $black url('../img/Group\ 17.svg') no-repeat top right;
  background-size: 90%;
  color: $black;

  div.coverage {
    display: flex;
    h3 {
      margin-top: 0;
      font-family: 'Open Sans';
      font-weight: bold;
      font-size: x-large;
    }
  }

  article.squad-info {
    margin-bottom: 40px;

    p {
      font-family: 'Open Sans';
    }

    @include lg {
      padding-right: 45%;
      margin-bottom: 70px;
    }
  }

  .cards {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .card {
      text-align: center;
      border-radius: 9px;
      cursor: pointer;
      transition: box-shadow $transitionTime ease-out;
      margin-bottom: 15px;

      img.profile-pic {
        width: 100%;

        @include md {
          margin-bottom: 25px;
        }
      }

      footer {
        padding-bottom: 30px;

        h3 {
          font-family: 'Open Sans';
          font-weight: bold;
          color: $blue;
          margin-bottom: 8px;
        }

        p {
          font-family: 'Open Sans';
          font-style: italic;
          font-size: 15px;
          margin: 0;
          margin-bottom: 8px;
        }

        img {
          width: 65px;
        }
      }

      &:hover {
        box-shadow: 0 0 8px rgba(255, 255, 255, 1);
      }

      @include md {
        width: 28%;
        margin-bottom: 0px;
      }
    }

    @include md {
      flex-direction: row;
    }
  }
}

/* Teams (Squad) page
   ========================================================================== */

section.features-page {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-right: 0;

  background: $white url('../img/Group\ 15.svg') no-repeat top left;
  background-size: 70%;

  article.feature-info {
    margin-top: 45px;
    header h2,
    p {
      color: $black;
    }

    p {
      font-family: 'Open Sans';
      font-size: 16px;
    }

    @include lg {
    }
  }

  .jScrolly {
    img.slider-controls {
      width: 125px;
      margin: 20px 0;
    }

    .jPanel {
      display: none;
    }

    .slider {
      display: flex;

      > .card {
        position: relative;
        min-width: 340px;
        min-height: 320px;
        border-radius: 9px;
        margin-right: 20px;
        cursor: pointer;

        footer {
          position: absolute;
          bottom: 0;
          z-index: 1;
          text-align: center;
          background-color: #1d202c;
          border-radius: 0 0 9px 9px;
          width: 100%;
          height: 46px;
          line-height: 46px;
          font-size: 18px;
          font-family: 'Open Sans';
          font-weight: bold;
        }

        &:hover > .back {
          opacity: 0.95;
        }

        article.back {
          position: absolute;
          background-color: $blue;
          width: 100%;
          height: 100%;
          z-index: 10;
          border-radius: 9px;
          opacity: 0;
          overflow: hidden;
          transition: opacity $transitionTime * 2 ease-in-out;

          h2 {
            font-family: 'Exo 2';
            font-size: 41px;
            margin: 20px 0 10px;
            padding: 12px;
          }

          p {
            font-family: 'Open Sans';
            padding: 12px;
            padding-top: 0;
            margin-top: 0;
          }
        }
      }

      #customer-oriented {
        background: url(../img/customer.jpg) no-repeat;
      }

      #result-oriented {
        background: url(../img/accounts.jpeg) no-repeat;
      }

      #business-oriented {
        background: url(../img/business-woman.jpg) no-repeat;
      }
    }

    @include lg {
    }
  }

  @include lg {
    flex-direction: row;
  }
}

/* Testimonials page
   ========================================================================== */

section.testimonials-page {
  background: $black url('../img/Group\ 15.svg') no-repeat bottom right;
  background-size: 60%;
  background-color: #f7f7f7;

  article.testimonials-info {
    margin-bottom: 30px;

    header {
      margin-bottom: 60px;
    }

    header > .title,
    p {
      color: #1d202c;
    }

    p {
      font-family: 'Open sans';
    }
  }

  .content {
    span.bordered {
      font-family: 'Open Sans';
      font-size: 1.4em;
      color: #1d202c;
      display: inline-block;

      @include sm {
        width: 40%;
        padding-right: 4%;
        padding-left: 4%;
        margin-bottom: 12px;
      }

      @include md {
        width: 20%;
        padding-right: 2%;
        padding-left: 2%;
        margin-bottom: 15px;
      }

      @include xs {
        width: 80%;
        padding-left: 9%;
        margin-bottom: 8px;
      }
    }
  }

  /* .cards {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    article.card {
      background-color: #fcfcff;
      border: 1px solid #e1e1e1;
      border-radius: 9px;
      cursor: pointer;
      transition: box-shadow $transitionTime ease-out;
      margin-bottom: 30px;
      padding: 30px;

      &:hover {
        box-shadow: 0 0px 15px rgba(0, 110, 246, 0.2);
      }

      header h3 {
        color: #1d202c;
        font-family: 'Open Sans';
        font-size: 600;
      }

      > p {
        font-family: 'Open Sans';
        color: #2b2b2b;
        font-size: 15px;
        margin-bottom: 40px;
      }

      footer {
        display: flex;
        flex-direction: row;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        padding-top: 30px;

        img {
          width: 45px;
          height: 45px;
          margin-right: 20px;
        }

        .user-info {
          h4 {
            font-family: 'Open Sans';
            font-weight: bold;
            color: $blue;
            margin: 0;
          }

          p {
            margin-top: 8px;
            font-family: 'Open Sans';
            font-weight: 600;
            color: #2b2b2b;

            span {
              color: $blue;
            }
          }
        }
      }

      @include lg {
        width: 23.5%;
      }

      @include xl {
        padding: 40px;
      }
    }

    @include lg {
      flex-direction: row;
    }
  } 
  

  p.feedback {
    color: $black;
    text-align: center;
    font-family: 'Open Sans';
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 0;

    a {
      text-decoration: none;
      color: $blue;
    }

    @include lg {
      margin-top: 60px;
    }
  }
  */
}

/* Contact page
   ========================================================================== */

section.contact-page {
  background: $black url('../img/Group\ 19.svg') no-repeat top left;
  background-size: 91%;
  background-color: rgba(0, 114, 255, 0.1);

  h2 {
    color: #1d202c;
    font-family: 'Exo 2';
    font-weight: 600;
    font-size: 41px;
    margin-top: 0;
  }

  p {
    font-family: 'Open Sans';
    color: $black;
    margin-bottom: 50px;
  }

  h3#contact_types {
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: -3px;

    a {
      text-decoration: none;
      padding-bottom: 20px;
      display: inline-block;
      color: #1d202c;
      transition: color 0.175s ease-in-out;

      &:hover,
      &.active {
        cursor: initial;
        color: $blue;
        border-bottom: 3px solid $blue;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      @include lg {
        &:not(:last-child) {
          margin-right: 64px;
        }
      }
    }

    @include md {
      font-size: 18px;
    }
  }

  hr.separator {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    margin-bottom: 50px;
    margin-top: 0;
    height: 3px;
    border-color: #fff;
    border-style: hidden;
    background: white;
  }

  .contact-form {
    .input-wrapper {
      display: flex;
      flex-direction: column;

      input,
      textarea {
        flex: 1;
        padding: 25px;
        margin-bottom: 35px;
        border: none;
        border-radius: 9px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 18px;
        color: #555555;

        &::placeholder {
          color: #d5d5d5;
        }

        &:focus {
          outline: none;
        }
      }

      @include lg {
        flex-direction: row;

        input + input {
          margin-left: 20px;
        }
      }
    }

    button {
      margin-top: 15px;
      padding-left: 40px;
      padding-right: 40px;

      &:hover {
        background-color: #ffffff;
        color: $blue;
      }
    }
  }

  p.feedback {
    color: $black;
    text-align: center;
    font-family: 'Open Sans';
    font-weight: bolder;
    font-size: 1.2em;
    margin-bottom: 10px;
    margin-top: 0;

    span {
      // text-decoration: underline;
      color: $blue;
    }

    @include lg {
      margin-top: 40px;
    }
  }
}

/* Footer
  ========================================================================== */

footer.main-footer {
  background: $black url('../img/Group\ 17.svg') no-repeat top left;
  background-size: 91%;
  background-color: $black;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  font-size: 15px;

  article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;

    header {
      display: flex;
      align-items: center;

      h3 {
        font-weight: 600;
        font-size: 22px;
        margin: 0;
      }

      @include lg {
        margin-bottom: 20px;
        height: 60px;
      }
    }

    div.content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  article.info {
    flex: 5;

    header .site-logo {
      width: 200px;
    }

    .content {
      height: 190px;

      @include md {
        height: 140px;
      }
    }

    p {
      margin-bottom: 0;

      &:last-child {
        margin-top: 0;
      }
    }

    img {
      width: 70px;
    }

    .social-media-links img {
      width: 24px;
      margin-right: 5px;
    }

    @include lg {
      padding-right: 100px;

      .content {
        height: 200px;
      }
    }
  }

  article.quick-links {
    flex: 4;

    .content {
      height: inherit;
    }

    ul {
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      padding: 0;
      height: 100%;

      li {
        a {
          text-decoration: none;
        }
      }
    }

    @include lg {
      .content {
        height: 200px;

        ul {
          flex-direction: column;
        }
      }
    }
  }

  article.subscription {
    flex: 4;

    .content {
      height: 160px;
    }

    form {
      display: flex;
    }

    input,
    button {
      height: 42px;
      line-height: 42px;
      border: 1px solid $white;
    }

    input {
      border-radius: 21px;
      background-color: transparent;
      padding: 0 70px 0 40px;
      width: 100%;

      &::placeholder {
        font-size: 13px;
        color: $white;
      }
    }

    button {
      margin-left: -60px;
      width: 100px;
      height: 44px;

      img {
        width: 18px;
        height: 14px;
        margin-bottom: 10px;
      }
    }

    p#email-privacy-text {
      font-style: italic;
      opacity: 0.35;
    }

    @include lg {
      .content {
        height: 200px;
      }
    }
  }

  @include lg {
    flex-direction: row;
  }
}

/** Industries we cater */

.indusrt-list.active {
  font-size: 14px;
  line-height: 1.42857143;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 !important;
  position: relative;

  ul.row {
    font-size: 14px;
    line-height: 1.42857143;
    font-family: 'Segoe UI';
    color: #000;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    column-count: 3;
    column-gap: 2em;

    li {
      -webkit-tap-highlight-color: transparent;
      font-family: 'Segoe UI';
      list-style: none;
      box-sizing: border-box;
      background: #fff !important;
      padding: 0 !important;
      height: auto !important;
      border-top: 1px solid #dbdbdb;
      color: #525252;
      float: left;
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      margin-right: 15px;
      clear: none;

      a {
        list-style: none;
        font-size: 16px;
        line-height: 22px;
        box-sizing: border-box;
        outline: 0;
        text-decoration: none;
        position: relative;
        min-height: 1px;
        float: left;
        color: #525252;
        width: 100% !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: url('https://www.abcconsultants.in/wp-content/uploads/2017/10/icon_01-1.png')
          left 10px center no-repeat;
        padding: 15px 0px 15px 65px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .indusrt-list ul {
    -webkit-column-count: 1 !important;
    -moz-column-count: 1 !important;
    column-count: 1 !important;
  }
  .indusrt-list ul li {
    line-height: 60px !important;
    overflow: hidden;
  }
}
